var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title"},[_vm._v("\n                    Password change\n                ")]),_c('div',{staticClass:"custom-modal-text text-left"},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submitChangeMyPassword($event)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"current_password"}},[_vm._v("\n                                Current Password\n                            ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordChange.current_password),expression:"passwordChange.current_password"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.passwordChange.current_password
                                            .$error || _vm.wrongCurrentPassword
                                },attrs:{"id":"current_password","type":"password","placeholder":"Current password","error-messages":_vm.currentPasswordErrors},domProps:{"value":(_vm.passwordChange.current_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordChange, "current_password", $event.target.value)},function($event){_vm.$v.passwordChange.current_password
                                        .$touch;
                                    _vm.wrongCurrentPassword = false;}],"blur":function($event){_vm.$v.passwordChange.current_password
                                        .$touch;}}}),(_vm.currentPasswordErrors.length > 0)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                "+_vm._s(_vm.currentPasswordErrors[0])+"\n                            ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("New password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordChange.password),expression:"passwordChange.password"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.passwordChange.password.$error
                                },attrs:{"id":"password","type":"password","placeholder":"New password","error-messages":_vm.passwordErrors},domProps:{"value":(_vm.passwordChange.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordChange, "password", $event.target.value)},_vm.$v.passwordChange.password.$touch],"blur":_vm.$v.passwordChange.password.$touch}}),(_vm.passwordErrors.length > 0)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                "+_vm._s(_vm.passwordErrors[0])+"\n                            ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password_confirmation"}},[_vm._v("\n                                Password confirmation\n                            ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.passwordChange.password_confirmation
                                ),expression:"\n                                    passwordChange.password_confirmation\n                                "}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.$v.passwordChange
                                            .password_confirmation.$error
                                },attrs:{"id":"password_confirmation","type":"password","placeholder":"Password confirmation","error-messages":_vm.passwordConfirmationErrors},domProps:{"value":(
                                    _vm.passwordChange.password_confirmation
                                )},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordChange, "password_confirmation", $event.target.value)},function($event){_vm.$v.passwordChange.password_confirmation
                                        .$touch;}],"blur":function($event){_vm.$v.passwordChange.password_confirmation
                                        .$touch;}}}),(_vm.passwordConfirmationErrors.length > 0)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                "+_vm._s(_vm.passwordConfirmationErrors[0])+"\n                            ")]):_vm._e()]),_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v("\n                            Change password\n                        ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }