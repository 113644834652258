<template>
    <div class="row">
        <were-changes-alert :were-changes="wereChanges" @save="submitUserDataHandler" />
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="custom-modal-text text-left">
                        <form @submit.stop.prevent="submitUserDataHandler">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                    id="email"
                                    v-model="user.email"
                                    :class="{ 'is-invalid': $v.user.email.$error || isEmailTaken }"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Email"
                                    :error-messages="emailErrors"
                                    @input="$v.user.email.$touch; isEmailTaken = false"
                                    @blur="$v.user.email.$touch"
                                />
                                <div
                                    v-if="emailErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ emailErrors[0] }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="first_name">First Name</label>
                                <input
                                    id="first_name"
                                    v-model="user.first_name"
                                    :class="{ 'is-invalid': $v.user.first_name.$error }"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter first name"
                                    :error-messages="firstNameErrors"
                                    @input="$v.user.first_name.$touch"
                                    @blur="$v.user.first_name.$touch"
                                />
                                <div
                                    v-if="firstNameErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ firstNameErrors[0] }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="last_name">Last Name</label>
                                <input
                                    id="last_name"
                                    v-model="user.last_name"
                                    :class="{ 'is-invalid': $v.user.last_name.$error }"
                                    type="text"
                                    class="form-control"
                                    placeholder="Last Name"
                                    :error-messages="lastNameErrors"
                                    @input="$v.user.last_name.$touch"
                                    @blur="$v.user.last_name.$touch"
                                />
                                <div
                                    v-if="lastNameErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ lastNameErrors[0] }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                <input
                                    id="phone"
                                    v-model="user.phone"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter phone number"
                                />
                            </div>
                            <div class="form-group">
                                <b-form-checkbox
                                    id="order_notifications"
                                    v-model="user.enable_order_notifications"
                                    name="order_notifications"
                                    class="checkbox-circle"
                                >
                                    Enable Order Notifications
                                </b-form-checkbox>
                            </div>
                            <button
                                type="submit"
                                class="btn btn-success waves-effect waves-light"
                            >
                                Save changes
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: {
        WereChangesAlert
    },

    data() {
        return {
            user: {},
            isEmailTaken: false,
            initUserStringify: ''
        };
    },

    validations: {
        user: {
            email: {
                required,
                email,
            },
            first_name: {
                required,
            },
            last_name: {
                required,
            },
        },
    },

    computed: {
        wereChanges() {
            return this.initUserStringify !== JSON.stringify(this.user);
        },

        lastNameErrors() {
            const errors = [];

            if (!this.$v.user.last_name.$dirty) return errors;

            !this.$v.user.last_name.required &&
                errors.push('Last name is required.');

            return errors;
        },

        firstNameErrors() {
            const errors = [];

            if (!this.$v.user.first_name.$dirty) return errors;

            !this.$v.user.first_name.required &&
                errors.push('First name is required.');

            return errors;
        },

        emailErrors() {
            const errors = [];

            if (!this.$v.user.email.$dirty) return errors;

            this.isEmailTaken && errors.push('E-mail has already been taken');
            !this.$v.user.email.email && errors.push('Must be valid e-mail');
            !this.$v.user.email.required && errors.push('E-mail is required');

            return errors;
        },
    },

    async mounted() {
        this.user = await this.getMe();
        this.initUserStringify = JSON.stringify(this.user);
    },

    methods: {
        ...mapActions({
            getMe: 'users/getMe',
            updateMyAccount: 'users/updateMyAccount',
        }),

        async submitUserDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const dataToSend = {
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email,
                phone: this.user.phone,
                enable_order_notifications: this.user
                    .enable_order_notifications,
            };

            try {
                this.user = await this.updateMyAccount(dataToSend);
                window.localStorage.setItem(
                    'auth.currentUser',
                    JSON.stringify(this.user)
                );

                this.initUserStringify = JSON.stringify(this.user);

                this.$toaster('Account has been updated!');
            } catch (err) {
                console.error(err);

                if (
                    err.response.status === 422 &&
                    err.response.data.errors.email
                ) {
                    this.isEmailTaken = true;
                } else {
                    this.$toasterError();
                }
            }
        },
    },
};
</script>
