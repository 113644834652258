<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        Password change
                    </div>
                    <div class="custom-modal-text text-left">
                        <form @submit.stop.prevent="submitChangeMyPassword">
                            <div class="form-group">
                                <label for="current_password">
                                    Current Password
                                </label>
                                <input
                                    id="current_password"
                                    v-model="passwordChange.current_password"
                                    :class="{
                                        'is-invalid':
                                            $v.passwordChange.current_password
                                                .$error || wrongCurrentPassword
                                    }"
                                    type="password"
                                    class="form-control"
                                    placeholder="Current password"
                                    :error-messages="currentPasswordErrors"
                                    @input="
                                        $v.passwordChange.current_password
                                            .$touch;
                                        wrongCurrentPassword = false;
                                    "
                                    @blur="
                                        $v.passwordChange.current_password
                                            .$touch;
                                    "
                                />
                                <div
                                    v-if="currentPasswordErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ currentPasswordErrors[0] }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">New password</label>
                                <input
                                    id="password"
                                    v-model="passwordChange.password"
                                    :class="{
                                        'is-invalid':
                                            $v.passwordChange.password.$error
                                    }"
                                    type="password"
                                    class="form-control"
                                    placeholder="New password"
                                    :error-messages="passwordErrors"
                                    @input="$v.passwordChange.password.$touch"
                                    @blur="$v.passwordChange.password.$touch"
                                />
                                <div
                                    v-if="passwordErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ passwordErrors[0] }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password_confirmation">
                                    Password confirmation
                                </label>
                                <input
                                    id="password_confirmation"
                                    v-model="
                                        passwordChange.password_confirmation
                                    "
                                    :class="{
                                        'is-invalid':
                                            $v.passwordChange
                                                .password_confirmation.$error
                                    }"
                                    type="password"
                                    class="form-control"
                                    placeholder="Password confirmation"
                                    :error-messages="passwordConfirmationErrors"
                                    @input="
                                        $v.passwordChange.password_confirmation
                                            .$touch;
                                    "
                                    @blur="
                                        $v.passwordChange.password_confirmation
                                            .$touch;
                                    "
                                />
                                <div
                                    v-if="passwordConfirmationErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ passwordConfirmationErrors[0] }}
                                </div>
                            </div>
                            <button
                                type="submit"
                                class="btn btn-success waves-effect waves-light"
                            >
                                Change password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            passwordChange: {},
            wrongCurrentPassword: false
        };
    },

    validations: {
        passwordChange: {
            current_password: { required },
            password: {
                required,
                minLength: minLength(6),
                containDigit: v => {
                    return /\d/.test(v);
                }
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },

    computed: {
        currentPasswordErrors() {
            const errors = [];

            if (!this.$v.passwordChange.current_password.$dirty) return errors;

            this.wrongCurrentPassword && errors.push('Wrong current password.');
            !this.$v.passwordChange.current_password.required &&
                errors.push('Current Password is required.');

            return errors;
        },

        passwordErrors() {
            const errors = [];

            if (!this.$v.passwordChange.password.$dirty) return errors;

            !this.$v.passwordChange.password.required &&
                errors.push('New Password is required.');
            !this.$v.passwordChange.password.minLength &&
                errors.push(
                    'New Password cannot contain less than 6 characters!'
                );
            !this.$v.passwordChange.password.containDigit &&
                errors.push('New Password must contain numbers and letters!');

            return errors;
        },

        passwordConfirmationErrors() {
            const errors = [];

            if (!this.$v.passwordChange.password_confirmation.$dirty)
                return errors;

            !this.$v.passwordChange.password_confirmation.required &&
                errors.push('Password Confirmation is required');
            !this.$v.passwordChange.password_confirmation.sameAsPassword &&
                errors.push(
                    'Password confirmation must be equal to New Password'
                );

            return errors;
        }
    },

    methods: {
        ...mapActions({
            changeMyPassword: 'users/updateMyPassword'
        }),

        async submitChangeMyPassword() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.changeMyPassword(this.passwordChange);
                this.passwordChange = {};

                this.$v.$reset();

                this.$toaster('Password has been changed!');
            } catch (err) {
                console.error(err);

                if (
                    err.response.status === 422 &&
                    err.response.data.errors.current_password
                ) {
                    this.wrongCurrentPassword = true;
                } else {
                    let message = null;

                    if (err.response.data.errors) {
                        message = err.response.data.errors.join('. ');
                    }

                    this.$toasterError(message);
                }
            }
        }
    }
};
</script>
