<template>
    <layout>
        <page-header :title="title" :items="items" />
        <update-account-card></update-account-card>
        <change-password-card></change-password-card>
    </layout>
</template>

<script>
import appConfig from '@src/app.config';
import ChangePasswordCard from './ChangePasswordCard';
import UpdateAccountCard from './UpdateAccountCard';

export default {
    page: {
        title: 'My account',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ChangePasswordCard,
        UpdateAccountCard
    },

    data() {
        return {
            title: 'My Account',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'My account',
                    active: true
                }
            ]
        };
    }
};
</script>
